import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`TuringTrader API Access`}</h1>
    <Breadcrumbs text="Help" to="/help/" mdxType="Breadcrumbs" />
    <p><em parentName="p">{`TuringTrader.com`}</em>{` provides an API to access your portfolio's asset allocation. With this API, you can create your own applications in a programming language of your choice, or as macro-enabled spreadsheets, that pull portfolio allocations directly from our website. You can use this to track your investments, or to automate parts (or even all) of the rebalancing process.`}</p>
    <Border mdxType="Border">
      <p css={`text-align: center;`}>
        <p>{`Using the `}<em parentName="p">{`TuringTrader`}</em>{` API requires an `}<strong parentName="p">{`Infinite`}</strong>{` membership.`}</p>
        <ButtonPrimary text="compare our plans" to="/sign-up/" mdxType="ButtonPrimary" />
      </p>
    </Border>
    <h2>{`API Access`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`Learn More`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://realpython.com/api-integration-in-python/"
          }}>{`Python and REST APIs`}</a></li>
      </ul>
    </Aside>
    <p>{`The `}<em parentName="p">{`TuringTrader`}</em>{` API provides a simple `}<em parentName="p">{`https`}</em>{` endpoint, which returns the current asset allocation. The format of the URL is as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`https://www.turingtrader.com/api/v1/alloc?
    id=API_KEY&
    pf=PORTFOLIO_SLUG
`}</code></pre>
    <p>{`This URL includes the following parameters:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`API_KEY`}</inlineCode>{`: The API key identifies you, similar to your account's login and password. You can find your API key on the `}<a parentName="li" {...{
          "href": "/help/member-info"
        }}>{`member-info page`}</a>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`PORTFOLIO_SLUG`}</inlineCode>{`: This is the portfolio's name, as used in the URL of the portfolio page. If your portfolio's page is at `}<inlineCode parentName="li">{`www.turingtrader.com/portfolios/classic-60-40`}</inlineCode>{`, the slug is `}<inlineCode parentName="li">{`classic-40-40`}</inlineCode>{`. However, there is an additional complication. Occasionally, we release updated versions of our portfolios. In these cases, the slug contains a version number to distinguish between the strategy versions. If you are using version 3 of our `}<em parentName="li">{`All Stars Total Return`}</em>{` portfolio, your slug is `}<inlineCode parentName="li">{`all-stars-tr-v3`}</inlineCode>{`. We did this to make sure that you are not blindsided by portfolio updates on our end.`}</li>
    </ul>
    <p>{`Here is an example of a valid URL: `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/api/v1/alloc?id=123&pf=classic-60-40"
      }}>{`https://www.turingtrader.com/api/v1/alloc?id=123&pf=classic-60-40`}</a>{`. The endpoint returns an object in `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/JSON"
      }}>{`JSON format`}</a>{`, similar to this:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    portfolio: "classic-60-40",
    rebalanceDate: "October 13, 2020",
    columnFormat: ["Symbol", "Name", "Allocation", "Price"],
    assetAllocation: [
      ["SPY", "SPDR S&P 500 Trust ETF", "60.00%", "350.13"],
      ["TLT", "iShares 20 Plus Year Treasury Bond ETF", "40.00%", "161.75"],
    ],
}
`}</code></pre>
    <p>{`The response includes the following fields:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`portfolio`}</inlineCode>{`: This is the portfolio slug you passed in as a parameter.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`rebalanceDate`}</inlineCode>{`: This is the date of the portfolio's last rebalancing. The date is specified in the exchange's time zone, and the asset allocation was calculated after the exchange closed on that day.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`columnFormat`}</inlineCode>{`: This field provides the format of the asset allocation rows. Each row includes the asset's ticker symbol, a descriptive name, the percentage of capital allocated to the asset, and the closing price.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`assetAllocation`}</inlineCode>{`: This is an array of arrays, one row per asset, with each row formatted according to `}<inlineCode parentName="li">{`columnFormat`}</inlineCode>{`. `}</li>
    </ul>
    <p>{`It is essential to understand that the API returns the portfolio allocation in its raw form before any additional data cleanup. Therefore, consumers of the asset allocation should watch out for the following:`}</p>
    <ul>
      <li parentName="ul">{`The asset weights may add up to more than 100%. This can happen when prices move fast between the close on rebalancing day and the following open. Consumers should sum up the total asset weights, determine any excess exposure, and scale back the positions accordingly if required.`}</li>
      <li parentName="ul">{`The asset weights may add up to less than 100%. This may happen when prices move fast before filling the rebalancing order or with portfolios that keep part of the capital in cash during bearish markets. Thus, consumers should assume that any unassigned capital is held in cash.`}</li>
    </ul>
    <h2>{`Excel-based Rebalancing Tool`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/api/TuringTrader-API-v2.1.xlsm"
          }}>{`Excel Rebalancing Tool`}</a></li>
      </ul>
    </Aside>
    <p>{`There are many use cases for the API, but most relate to automating at least part of the rebalancing procedure. To aid the development of custom rebalancing solutions, we provide sample code in the form of an `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/api/TuringTrader-API-v2.0.xlsm"
      }}>{`Excel worksheet with VBA macros`}</a>{`. This worksheet demonstrates accessing the API and interpreting the results.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "739px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b41e63d9789b0bd3e7dfa4d3ad717db1/f1d1f/setup.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.333333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA2klEQVQI1z3I3U6DMBiAYe7/WjQemJh4MjMT44BCocVMI8hPRhXaCW1poKzuW/TAN8/R6/VcKKW01sMwaK3kMJppUlKaadJSKinneV6WZV3tYq1zzv62/p3Vu77bxjEOAxQE4XMY9cdvAHDnswM4AfwAFEWOEIoxTkn6tNuhKCYZJRmNk8RjjHHOhRB917EEcRoeX3BPI06j8TWVb1R8fbaMtc2hLZs6Lw9l3XxUdV62VePRlGQ008bAyXX+bYGvKnwj3u9Ns4HRd8WG7fdmsUB9SB6APALZ/rsAbaLVKdX76IgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "setup",
            "title": "setup",
            "src": "/static/b41e63d9789b0bd3e7dfa4d3ad717db1/f1d1f/setup.png",
            "srcSet": ["/static/b41e63d9789b0bd3e7dfa4d3ad717db1/5a46d/setup.png 300w", "/static/b41e63d9789b0bd3e7dfa4d3ad717db1/0a47e/setup.png 600w", "/static/b41e63d9789b0bd3e7dfa4d3ad717db1/f1d1f/setup.png 739w"],
            "sizes": "(max-width: 739px) 100vw, 739px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Before using the tool for the first time, you need to perform the following setup steps:`}</p>
    <ul>
      <li parentName="ul">{`Enter your API key in `}<em parentName="li">{`field I5`}</em>{`.`}</li>
      <li parentName="ul">{`Enter the portfolio slug in `}<em parentName="li">{`field E5`}</em>{`.`}</li>
      <li parentName="ul">{`Optional: give your account a user-friendly name in `}<em parentName="li">{`field E4`}</em>{`.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "739px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/daf9e64397ba7b9ac05c5f297a82194e/f1d1f/first.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA2ElEQVQI1x3E3U6DMBgAUN7/ZbzbjSbOEchkZcRSvxbWH+jXVmBzs2MhZjHRxHNxEqntZgvrvKbN2A3fOizmY9Fhke4m3Sz9ggL63VoVz3cLZ2RblhLICM9DNAmGIx5vOEY3RqF8zQ0FI6R345cfox3iyeHVa9+yQYG3rejeQVKcunC2iRKSvBDYN+RNsjZQgVQ41vrGTCVVFeBg1NwDkhQ3j12VVQfCDd3zQnqeiNdytcrLtObyU9i56efmf9FdQV3o4TKp9texO81+VAVPD8wULkpzAqp3f9QKzmrU6/ljAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "first",
            "title": "first",
            "src": "/static/daf9e64397ba7b9ac05c5f297a82194e/f1d1f/first.png",
            "srcSet": ["/static/daf9e64397ba7b9ac05c5f297a82194e/5a46d/first.png 300w", "/static/daf9e64397ba7b9ac05c5f297a82194e/0a47e/first.png 600w", "/static/daf9e64397ba7b9ac05c5f297a82194e/f1d1f/first.png 739w"],
            "sizes": "(max-width: 739px) 100vw, 739px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You are now ready to rebalance your account for the first time. This first rebalancing is slightly different because your account might not be all in cash but hold some positions. To reflect this, you need to manually enter the names of your assets in `}<em parentName="p">{`column A`}</em>{` and the number of shares in `}<em parentName="p">{`column I`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5bd44c9dbe0c2c9331b93b2539f7ced5/50383/get-alloc.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAqUlEQVQI1x3G2wqCMAAAUP//k0IitRQLteVMc7ZZXpbzElK6uRIi4Twc5VFVlDWU1azrxWt4mmtmqiOw+GnhbQVBUk5ieM9y+krB+cjF32eWCrnebAOYmhcAkqIao5okDV6CEcOozcMw9/TU0XkKuyzYn3duZHmxnfdXpQCw3B/pMShdGBs2VDV/tbloJnVh6fjFAbTOoa/uWQyzyL/jMMJBmPgZJXmd/gDR2Jx4FKoVVAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "get alloc",
            "title": "get alloc",
            "src": "/static/5bd44c9dbe0c2c9331b93b2539f7ced5/50383/get-alloc.png",
            "srcSet": ["/static/5bd44c9dbe0c2c9331b93b2539f7ced5/5a46d/get-alloc.png 300w", "/static/5bd44c9dbe0c2c9331b93b2539f7ced5/0a47e/get-alloc.png 600w", "/static/5bd44c9dbe0c2c9331b93b2539f7ced5/50383/get-alloc.png 740w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The following steps are identical, whether you rebalance the account for the very first time or any subsequent rebalancing:`}</p>
    <ul>
      <li parentName="ul">{`Enter the current total account value in `}<em parentName="li">{`field E6`}</em>{`. This value is the sum of the `}<em parentName="li">{`net liquidation value`}</em>{` of all current positions, plus any excess cash held in the account. Please note that you need to manually update this value, even if you have saved the worksheet with the current holdings before.`}</li>
      <li parentName="ul">{`Click the `}<em parentName="li">{`Get Target Allocation`}</em>{` button to retrieve the most recent asset allocation from `}<em parentName="li">{`TuringTrader.com`}</em>{`.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "737px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9bd3c3d78f7680d43d43a0cd6afd88b0/d125e/rebalance.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABkklEQVQY0z3PaY+TQADGcb7/l9jXRhMTo1YttOWGFhYG5uCY4ZrhWrd0W3aNV8S0iSb/t788eSTmhZu7N9u719jL2fCd8pmK52t8pvySi5c2IwMweWD8aPAkkJ+5Yb4H7CBOTGpZ1RPW4byrOpI3YZyGcUqyqh0m0R95fzx27WXkTYaaBPI6z6qEUCjGuj+1UsIaxSV+OrhxjejXa+wR0UdSTm7APCjGkn4rwQjdLrILax0ZHwqgAfMjddcSsj3l1adIA8hEyMLQgJEWQRNCAzKfQRMPnrMM5LfAf1qSym9h7rRnyp8LYLyXkGqHkSDVaaX4ay38ogYrxfu8u1edJKnOHmzLjbz0yU+Olj4bD0pMHXFhzcx8/Z1EzL0X1rg8yRrYWmhnY9XBlk/j7IGKl4CMXFeXjtxwOnobVBzEmTYzi+2VRIx9cF1+2tl45xDNTbyYJ9UZF1PWXCCdRsdaOvzrhh/+YT4XebiVsO7eg4ZUT7IeK0a01oHpUx+KPahxMcX51JnGfzzs5Th3xO1z4st/AWgrlLf/jINhAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "rebalance",
            "title": "rebalance",
            "src": "/static/9bd3c3d78f7680d43d43a0cd6afd88b0/d125e/rebalance.png",
            "srcSet": ["/static/9bd3c3d78f7680d43d43a0cd6afd88b0/5a46d/rebalance.png 300w", "/static/9bd3c3d78f7680d43d43a0cd6afd88b0/0a47e/rebalance.png 600w", "/static/9bd3c3d78f7680d43d43a0cd6afd88b0/d125e/rebalance.png 737w"],
            "sizes": "(max-width: 737px) 100vw, 737px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once the API request has finished, you have all the information required to rebalance your account:`}</p>
    <ul>
      <li parentName="ul">{`The last rebalancing date for this portfolio is in `}<em parentName="li">{`field A9`}</em>{`.`}</li>
      <li parentName="ul">{`The target holdings for your account are in `}<em parentName="li">{`columns A to G`}</em>{`.`}</li>
      <li parentName="ul">{`The orders required to rebalance the account are in `}<em parentName="li">{`columns J to L`}</em>{`.`}</li>
    </ul>
    <p>{`You can now prepare these orders and submit them to your broker. We recommend thinking about orders that affect only a small dollar amount, e.g., less than $500. Skipping these orders will have minimal effect on your account's performance but may significantly reduce friction due to fees, commissions, and slippage. Read more about this topic in our article about `}<a parentName="p" {...{
        "href": "/help/account-rebalancing/"
      }}>{`portfolio rebalancing`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "736px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0c74205aae2bedcbbba1bb3816b5e571/f941f/done.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABfElEQVQY0yXNy2+bMACAcf7/w27bYdftMKnV1K5TmwevgEcTAhhj3rZJABcCzqtJpS7qolbtpO/60yelpBxNPMVEEdsw/kTrI62P5CNaH2jzXFxfB18+pV8/nyAQxHWhhgIDh+ChjSXe9Lzd8nbb9vuMVkFEcUxzVjfdnjfrqtutZX0zuF1eXlTyiE8BNTViKK3viCmQwpgaswzGD7oVehF3cGmj0sGVF/EZWjiKK+7BibgisMTwqry9QKFJiQ2hulhAycepn3QhFQPVlU0km+hOdUa6Z9p5Xh2d8bw21Nc6fCnQs2fAq+9Rg/ihqA6U9ankB4kbt5j0d4o9mvjjia8CbLksyAWtn7wJ4r9/ncHw9Gf8FwzxzY+4hnyXV9uMrWLJxxl8P69lAykAa1Zk+2XMtijrsuVjaJPe0M/345Otn5HFtJuE+3xPql1edIkUJgxlIi425jwDc2LY6QyVMFm5IU+Wj9FgJgzttU1f2uzfVKE/v6Ur/B8vRPoGIB1dcX3O0h0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "done",
            "title": "done",
            "src": "/static/0c74205aae2bedcbbba1bb3816b5e571/f941f/done.png",
            "srcSet": ["/static/0c74205aae2bedcbbba1bb3816b5e571/5a46d/done.png 300w", "/static/0c74205aae2bedcbbba1bb3816b5e571/0a47e/done.png 600w", "/static/0c74205aae2bedcbbba1bb3816b5e571/f941f/done.png 736w"],
            "sizes": "(max-width: 736px) 100vw, 736px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you submitted your orders, you should click the button labeled `}<em parentName="p">{`Copy Target to Current Allocation`}</em>{`. This will do the following:`}</p>
    <ul>
      <li parentName="ul">{`Set the `}<em parentName="li">{`field H9`}</em>{` to today's date.`}</li>
      <li parentName="ul">{`Copy the number of target shares from `}<em parentName="li">{`column G`}</em>{` to `}<em parentName="li">{`column I`}</em>{`.`}</li>
      <li parentName="ul">{`Clear the orders in `}<em parentName="li">{`columns J and L`}</em>{`.`}</li>
      <li parentName="ul">{`Remove any rows with zero shares.`}</li>
    </ul>
    <p>{`Don't forget to save the updated worksheet. Then, your next rebalancing will be much quicker, as you won't have to enter the current holdings.`}</p>
    <h2>{`Automated Trading`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`Brokerage APIs`}</strong></p>
      <ul>
        <li parentName="ul">{`Interactive Brokers: Official `}<a parentName="li" {...{
            "href": "https://interactivebrokers.github.io/tws-api/introduction.html"
          }}>{`TWS API`}</a>{` or `}<a parentName="li" {...{
            "href": "https://www.interactivebrokers.com/api/doc.html"
          }}>{`Client Portal Web API`}</a>{`.`}</li>
        <li parentName="ul">{`TD Ameritrade: `}<a parentName="li" {...{
            "href": "https://developer.tdameritrade.com/apis"
          }}>{`Official API documentation`}</a>{`.`}</li>
        <li parentName="ul">{`Alpaca: `}<a parentName="li" {...{
            "href": "https://alpaca.markets/docs/api-references/"
          }}>{`Offical API documentation`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.robin-stocks.com/"
          }}>{`Robin Stocks`}</a>{`: Unofficial API to access Robinhood, TD Ameritrade, and Gemini.`}</li>
      </ul>
    </Aside>
    <p>{`While the Excel-based rebalancing tool already saves a lot of time, some investors want to go further than this and fully automate their trading. Most brokerages offer an API to query the account status and place orders, and a fully automated system can be created by scripting some glue logic to connect the two.`}</p>
    <p>{`When considering a fully automated system, it is crucial to think about the following issues:`}</p>
    <ul>
      <li parentName="ul">{`Perform the necessary cleanup steps before using the API data. See above for more details.`}</li>
      <li parentName="ul">{`Check the `}<inlineCode parentName="li">{`rebalanceDate`}</inlineCode>{` field and avoid rebalancing on days where this is not required. While the weights should be adjusted appropriately, rounding errors might still lead to additional churn.`}</li>
      <li parentName="ul">{`Schedule all API operations late enough in the day. We recommend not relying on the API information any earlier than 6 hours after the markets closed. If possible, rebalancing should be scheduled closer to the market opening.`}</li>
      <li parentName="ul">{`The API might not return a separate row for assets with zero weight, leading to situations where the account may hold assets that are not mentioned in the allocation returned by the API. Your code will need to handle these situations.`}</li>
      <li parentName="ul">{`Avoid rebalancing of tiny quantities. Brokerages often charge a minimum commission, and the value of the assets rebalanced should be much larger than this minimum commission.`}</li>
      <li parentName="ul">{`Make sure to cancel any pending orders before submitting new orders. Otherwise, you might end up in situations where orders are emitted multiple times, leading to erroneous results.`}</li>
      <li parentName="ul">{`Think long and hard about authentication. Where and how do you store your account credentials? Can these be stolen or used in malicious ways? If at all possible, you should avoid storing your account credentials.`}</li>
    </ul>
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      